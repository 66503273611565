export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    _.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "dark",
        themes: {
          light: {},
          dark: {
            colors: {
              rating: "#acac02",
              linkColor: "#9e9eff",
            },
          },
        },
      },
    });
  });
});
